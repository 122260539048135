<template>
  <div>
     
     <v-row>
         <v-col cols="12">
                 <v-card>
                   <v-toolbar dense flat>
                     <v-spacer/>
                     <v-btn small color="info" @click="state='new',form=true"> <v-icon left color="secondary">mdi-plus</v-icon> Add Member</v-btn>
                   </v-toolbar>
 
                <v-toolbar>
                  <v-icon left color="secondary">mdi-account</v-icon> Accounts
                  <v-spacer/>
                  
                  <span style="width: 290px;">
                         <v-text-field
                             v-model="search"
                             append-icon="mdi-magnify"
                             label="Search"
                             single-line
                             dense
                             outlined
                             hide-details
                         ></v-text-field>
                  </span>
                </v-toolbar>
                <v-card-text>
                 <v-data-table
                     dense
                     :headers="headers"
                     :search="search"
                     :items="ACCOUNTS"
                     >
                     <template v-slot:body="{ items }">
                         <tbody>
                         <tr v-for="(item,index) in items" :key="index">
                             <td>
                             <v-icon small color="warning" @click="$router.push('/view-accounts/' + item.id).catch(err => {})">mdi-open-in-new</v-icon>
                             {{ item.id}}</td>
                             <td>{{ item.role }}</td>
                             <td>{{ item.username }}</td>
                             <td>{{ item.fullname }}</td>
                             <td>{{ item.contact_no }}</td>
                             <td> 
                                <v-btn class="ma-1" x-small  @click="account= item, transform=true"><v-icon small>mdi-format-list-text</v-icon> Transactions</v-btn>
                                <v-btn class="ma-1" x-small @click="account= item, load=true"><v-icon small>mdi-database</v-icon> Add LOAD</v-btn>
                              </td>
                         </tr>
                         </tbody>
                     </template>
                     </v-data-table>
                </v-card-text>
              </v-card>
         </v-col>
     </v-row>
     <va-account-form :show="form" :state="state" :data="account" @DialogEvent="formEv"/>
     <va-load-form :show="load" :account="account"  @DialogEvent="loadEv"/>
     <reports-account-transaction :show="transform" :data="account" @DialogEvent="tEvent"/>
 </div>
 
 </template>
 <script>
 import {
   mapMutations
 } from 'vuex'
   export default {
     data: () => ({
       form: false,
       load:false,
       accountID:'',
       state: 'new',
       passform: false,
       transform: false,
       conform: false,
         selected_coor:0,
         search:'',
         from_date:'',
         to_date: '',
         date_range:"",
         account:{},
         transactions:[],
         per_type:'Overall',
         headers: [
           { text: 'Account No.', value:"id"},
           { text: 'Type', value:"role"},
             { text: 'User', value:"username"},
             { text: 'Fullname', value:"fullname"},
            { text: 'Contact #'},
            { text: '---'}
         ],
     }),
     created() {
       this.PING()
      this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
         this.GET_ALL_ACCOUNTS(this.$IsNum(this.user.id),this.user.role)
         this.setDrawer(false)
     },
      mounted(){
        this.setLoggedIn(true)
         if (this.user == null) {
           this.$router.push('/')
         }else {
           this.$http.defaults.headers.common['Authorization'] = 'Bearer ' + this.user.token
           this.userDetails()
         }
     },
     computed:{
       isOnline(){
         return this.$store.state.is_online
       },
       user() {
         if(localStorage.user){
             return JSON.parse(localStorage.user)
         }
         return null
       },
       ACCOUNTS() {
         if(this.selected_coor) {
             var acct =this.$store.state.users.reduce((res, item)=>{
                 if(item.agent ==this.selected_coor) {
                   res.push(item)
                 }
                 return res
             }, [] )
             return acct
         }
         return this.$store.state.users
       },
        COORDINATORS(){
         var acct =this.$store.state.users.reduce((res, item)=>{
             if(item.role =='Coordinator') {
               res.push(item)
             }
             return res
         }, [] )
         acct.push({id: 0, fullname: "All Accounts"})
         return acct
       },
       AGENTS(){
         var acct = this.$store.state.users.reduce((res, item)=>{
             if(item.role =='Agent') {
               res.push(item)
             }
             return res
         }, [] )
         return acct
       },
       SUB(){
         var acct = this.$store.state.users.reduce((res, item)=>{
             if(item.role =='Sub-Agent') {
               res.push(item)
             }
             return res
         }, [] )
         return acct
       },
       SUB2(){
         var acct = this.$store.state.users.reduce((res, item)=>{
             if(item.role =='Sub-Agent2') {
               res.push(item)
             }
             return res
         }, [] )
         return acct
       }
     },
     methods: {
       ...mapMutations(['setDrawer', 'setAlertColor', 'setAlertText', 'setLoggedIn', 'setOverlayLoading']),
       loadEv() {
        this.load = false
        this.GET_ALL_ACCOUNTS(this.$IsNum(this.user.id),this.user.role)
       },
       pasEvent(){
         this.passform = false
         this.conform = false
       },
       tEvent() {
        this.transform = false
        this.GET_ALL_ACCOUNTS(this.$IsNum(this.user.id),this.user.role)
       },
       formEv(){
         this.form = false
         this.GET_ALL_ACCOUNTS(this.$IsNum(this.user.id),this.user.role)
       },
   
      userDetails(){
        console.log(this.user)
           this.$http.post("user/details",{id: this.user.id}).then(response => {
             console.log(response.data.account)
               if(response.data.status) {
                  this.account = response.data.account
               } 
           }).catch(e => {
             console.log(e.data)
           })
     },
     },
   }
 </script>